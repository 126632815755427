import includes from 'core-js/library/fn/string/virtual/includes';
import repeat from 'core-js/library/fn/string/virtual/repeat';

// Android4.x 対応
import 'core-js/es6/map';
import 'core-js/es6/set';

// IE 対応
import 'core-js/es6/symbol';
import 'core-js/es6/object';

String.prototype.includes = includes;
String.prototype.repeat = repeat;
